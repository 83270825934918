import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <body>
      <div class="container">
          <h1>Alright world time to take you on!</h1>
        <div class="loader"></div>
        <p>Welcome to Dunderdog</p>
        <br/>
        <p>Our new site will launch very soon</p>
        <p>Check back here later</p>
        <br/>
        <a href="https://dunderdog.se">dunderdog.se</a>
      </div>
      </body>
      </header>
    </div>
  );
}

export default App;
